import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import { useTranslation } from 'react-i18next';

import Layout from '@/components/Layout';
import Features from '@/components/Application/Features';
import AppPresentation from '@/components/AppPresentation';
// import Recipes from '@/components/Application/Recipes';
import TryIt from '@/components/Application/TryIt';

const PregnantRisks = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(`en`);
  }, []);

  return (
    <Layout>
      <AppPresentation />
      <Features />
      {/* <Recipes /> */}
      <TryIt />
    </Layout>
  );
};

export default PregnantRisks;

export const query = graphql`
  query {
    locales: allLocale(
      filter: {
        ns: { in: ["general", "application", "appPresentation"] }
        language: { eq: "en" }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
